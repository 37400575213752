/* eslint-disable */
import React, { useRef, useState } from 'react';
import { Radio, Modal, Select, Input, Space, Button, Tooltip, message, Row, Spin } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { convertFromRaw, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import { AutoTextSize } from 'auto-text-size';

import ResetIcon from 'components/Icons/ResetIcon';
import utils from 'utils/utils';
import Uploader from 'components/Uploader';
import CloseIcon from 'components/Icons/CloseIcon';

import { resizeImageForS3 } from 'utils/uploadToS3';
import { CaretDownOutlined, UploadOutlined } from '@ant-design/icons';

import './SignProposalModal.scss';

const { Option } = Select;

const SignProposalModal = ({
  sigName,
  setSigName,
  signing,
  setSigning,
  selectedOption,
  setSelectedOption,
  signProposal,
  dispatch,
  proposal,
  onCancel,
  configText,
  isClient,
  showOrHideSignSignature,
  btnText,
  clientWixPreview,
}) => {
  const defaultBtnText = configText('sign proposal button text');
  const [selectedOptionRequired, setSelectedOptionRequired] = useState(false);
  const [signatureType, setSignatureType] = useState(
    proposal.sigtype ? proposal.sigtype : 'sigtext'
  );
  const [signatureError, setSignatureError] = useState('');

  const headersection = proposal.draft.header;

  let namehtml = headersection.name;
  if (headersection.rawname) {
    const namecs = convertFromRaw(headersection.rawname);
    namehtml = stateToHTML(namecs);
  }

  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = namehtml;
  const namehtmlclean = tempDiv.textContent;
  const title = configText('sign uname proposal template', null, {
    user_first_name: namehtmlclean.split(' ')[0],
  });

  const ref = useRef();
  const inputFileRef = useRef(null);
  const [upload, status] = Uploader();

  const sigOptions =
    proposal.sigOptions.length && !clientWixPreview
      ? proposal.sigOptions
      : ['type', 'upload', 'draw'];

  const onChange = (type) => {
    setSignatureType(type);
    setSignatureError('');
  };

  const uploadSignature = () => {
    inputFileRef.current.click();
  };

  const updateSigOption = (value) => {
    setSelectedOption(value);
    setSelectedOptionRequired(false);
  };

  let testhtml = [];
  let pricing = null;
  // eslint-disable-next-line array-callback-return
  proposal.draft.sectionorder.map((draftKey) => {
    if (proposal.draft[draftKey] && proposal.draft[draftKey].raw) {
      const textcs = convertFromRaw(proposal.draft[draftKey].raw);
      stateToHTML(textcs, {
        blockRenderers: {
          atomic: (block) => {
            let data = undefined;

            try {
              const contentState = ContentState.createFromBlockArray([block]);
              const ent = block.getEntityAt(0);
              const entity = contentState.getEntity(ent);

              data = entity?.data;
            } catch (e) {
              // nothing in entityMap if block is atomic
              return null;
            }

            if (data && data.texcontent) {
              const json = JSON.parse(data.texcontent);
              if (json && json.pricing) {
                pricing = json.pricing.strategy;
                const jsonobj = json.deliverables;
                if (pricing === 'value') {
                  testhtml = [
                    {
                      t: configText('What option did you choose?') || 'What option did you choose?',
                      v: '',
                    },
                  ];
                  if (Object.keys(jsonobj.chosen).length > 1)
                    Object.keys(jsonobj.chosen).map((chosen) => {
                      const chosenName =
                        jsonobj.chosen[chosen] && jsonobj.chosen[chosen].name
                          ? jsonobj.chosen[chosen].name
                          : chosen;
                      testhtml.push({
                        v: configText(chosenName),
                        t: configText(chosenName),
                      });
                    });
                }
              }
            }
          },
        },
      });
    }
  });

  const handleSignatureUpload = async ({ file }) => {
    try {
      setSignatureError('');
      const size = { width: 300, height: 105 };

      const reSizedImage = await resizeImageForS3({
        file: file,
        path: 'signatures',
        size,
      });

      const { url } = await upload(reSizedImage, `props/${proposal._id}/signatures`);
      setSigName(url);
      return url;
    } catch (error) {
      console.log('error', error);
      message.error('Signing failed: Contact Support');
    }
  };

  const onSignProposal = async (e) => {
    e.preventDefault();

    if (!sigName && signatureType !== 'sigdraw') return setSignatureError('Signature Required!');

    if (!selectedOption && pricing && pricing === 'value' && testhtml && testhtml?.length > 1)
      return setSelectedOptionRequired(true);

    if (sigName && /(http(s?)):\/\//gi.test(sigName) && signatureType === 'sigtext')
      return setSignatureError('Signature Required!');

    if (signatureType === 'sigdraw' && ref?.current?.isEmpty())
      return setSignatureError('Signature Required!');

    setSigning(true);
    let url = undefined;

    if (signatureType === 'sigdraw') {
      const img = ref.current.toDataURL('image/png');
      const file = utils.dataURItoBlob(img);
      file['name'] = `${uuidv4()}.png`;
      url = await handleSignatureUpload({ file: file });
      if (!url) {
        setSigning(false);
        return setSignatureError('Failed to upload Signature!');
      }
    }

    if (isClient) {
      signProposal({ mode: '', signature: url });
    } else {
      setSigning(false);
      dispatch({
        type: 'setShowOrHideSignSignature',
        showOrHideSignSignature: false,
      });
      dispatch({
        type: 'setTestSign',
        testSign: {
          signature: url || '',
          showOrHideSignSignature,
        },
      });
    }
  };

  const isRtl = utils.isRTLProposal(proposal);

  return (
    <Modal
      className={`upload-signature ${isRtl ? 'language-rtl' : 'language-english'}`}
      centered
      visible
      onCancel={onCancel}
      closeIcon={<CloseIcon className="close-icon" />}
      footer={null}>
      <div>
        <h3 className="title">
          {utils.getSignText(title, btnText !== defaultBtnText, proposal?.language?.toLowerCase())}
        </h3>
      </div>
      {proposal && pricing && pricing === 'value' && testhtml && testhtml.length > 1 ? (
        <div className="sig-options">
          <Select
            placeholder=""
            defaultValue={selectedOption || testhtml[0].t}
            suffixIcon={<CaretDownOutlined color="red" />}
            onChange={updateSigOption}
            className={selectedOptionRequired && 'select-error'}>
            {testhtml.map((item) => (
              <Option key={item.v} className="">
                {item.t}
              </Option>
            ))}
          </Select>
          {selectedOptionRequired && <p className="error-field">*Field is required</p>}
        </div>
      ) : (
        <div />
      )}

      <div className="sign-type-wrapper">
        {sigOptions?.length > 1 && (
          <>
            {sigOptions.map((item) => {
              if (item === 'type') {
                return (
                  <SignOptionTab
                    text={configText('Type Signature')}
                    isActive={signatureType === 'sigtext'}
                    onClick={() => onChange('sigtext')}
                  />
                );
              }
              if (item === 'upload') {
                return (
                  <SignOptionTab
                    text={configText('Upload Signature')}
                    isActive={signatureType === 'sigimage'}
                    onClick={() => onChange('sigimage')}
                  />
                );
              }
              if (item === 'draw') {
                return (
                  <SignOptionTab
                    text={configText('Draw Signature')}
                    isActive={signatureType === 'sigdraw'}
                    onClick={() => onChange('sigdraw')}
                  />
                );
              }
            })}
          </>
        )}
      </div>

      <div className="signature-container">
        {signatureType === 'sigdraw' && (
          <>
            <h1 className="item-title">{configText('DRAW YOUR SIGNATURE BELOW')}</h1>
            <div className="signature-wrapper sig-draw">
              <SignatureCanvas
                penColor="black"
                backgroundColor="transparent"
                ref={ref}
                onBegin={() => setSignatureError('')}
                canvasProps={{
                  width: '385px',
                  height: '100%',
                }}
              />
            </div>
          </>
        )}

        {signatureType === 'sigimage' && (
          <>
            <h1 className="item-title">{configText('UPLOAD YOUR SIGNATURE BELOW')}</h1>

            <div className="signature-wrapper" onClick={uploadSignature}>
              {status.status === 'uploading' ? (
                <div className="loader">
                  <Spin />
                </div>
              ) : (
                <>
                  <div className="hoverlay">
                    <UploadOutlined />
                  </div>
                  {/(http(s?)):\/\//gi.test(sigName) ? (
                    <div className="sigpic-wrap">
                      <img src={sigName} alt={sigName} />
                    </div>
                  ) : (
                    <UploadOutlined className="upload-icon" />
                  )}
                  <input
                    type="file"
                    id="file_profile_sig"
                    className="file_profile_sig"
                    ref={inputFileRef}
                    style={{ display: 'none' }}
                    onChange={(e) =>
                      handleSignatureUpload({ file: e.target.files && e.target.files[0] })
                    }
                    accept="image/x-png,image/bmp,image/jpeg,image/svg+xml"
                  />{' '}
                </>
              )}
            </div>
          </>
        )}

        {signatureType === 'sigtext' && (
          <>
            <h1 className="item-title">{configText('TYPE YOUR FULL NAME')}</h1>
            <Input
              id="name"
              maxLength="30"
              name="name"
              placeholder={configText('sign dialog your name')}
              type="text"
              className="typed-signature"
              value={!/(http(s?)):\/\//gi.test(sigName) ? sigName : ''}
              onChange={(e) => {
                setSignatureError('');
                setSigName(e.target.value);
              }}
            />

            <div className="signature-wrapper signature-text">
              {sigName && !/(http(s?)):\/\//gi.test(sigName) ? (
                <AutoTextSize maxFontSizePx={40}>{sigName}</AutoTextSize>
              ) : (
                '\u00A0'
              )}
            </div>
          </>
        )}
      </div>

      <div className="accept-text">{configText('sign dialog terms')}</div>

      {signatureError && (
        <Row justify="center" className="error-field">
          Signature Required
        </Row>
      )}

      <div className="button-wrapper">
        <Button
          className="button confirm"
          disabled={status.status === 'uploading'}
          onClick={onSignProposal}
          loading={signing}>
          {signing ? 'SIGNING' : btnText}
        </Button>
        <Button
          className="button cancel m-hide"
          disabled={status.status === 'uploading'}
          onClick={onCancel}>
          {configText('sign dialog cancel')}
        </Button>
        <div className="spacer m-hide" />
        <Tooltip title={configText('Reset Signature')} className="m-hide">
          <ResetIcon
            disabled={status.status === 'uploading'}
            onClick={() => {
              setSigName('');
              ref?.current?.clear();
            }}
          />
        </Tooltip>
        <Row className="m-show m-reset-wrapper">
          <Button
            disabled={status.status === 'uploading'}
            className="button cancel"
            onClick={onCancel}>
            {configText('sign dialog cancel')}
          </Button>
          <Button
            className="button cancel reset"
            disabled={status.status === 'uploading'}
            onClick={() => {
              setSigName('');
              ref?.current?.clear();
            }}>
            <ResetIcon />
          </Button>
        </Row>
      </div>
    </Modal>
  );
};

const SignOptionTab = ({ isActive, text, onClick }) => {
  return (
    <div className={`sign-tab ${isActive ? 'active' : ''}`} onClick={onClick}>
      <span>{text}</span>
    </div>
  );
};

SignProposalModal.defaultProps = {
  isClient: false,
  user: '',
  showOrHideSignSignature: false,
};

SignProposalModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  proposal: PropTypes.instanceOf(Object).isRequired,
  configText: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  isClient: PropTypes.bool,
  showOrHideSignSignature: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  clientWixPreview: PropTypes.bool,
};

export default SignProposalModal;
