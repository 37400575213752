/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Input, Row, Col, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useIntercom } from 'react-use-intercom';
import { useGoogleLogin } from '@react-oauth/google';

import Path from 'routes/path';
import WhatsNew from 'components/WhatsNew/WhatsNew';
import Loader from 'components/Loader';
import PasswordEyeIcon from 'components/Icons/PasswordEyeIcon';
import PasswordEyeShutIcon from 'components/Icons/PasswordEyeShutIcon';
import utils from 'utils/utils';
import LoginFailed from './components/LoginFailed';
import CustomGoogleButton from 'components/CustomGoogleButton';
import images from 'constants/images';

import { USER_LOGIN, USER_GOOGLE_LOGIN, USER_WIX_LOGIN } from 'graphql/queries/userQueries';
import {
  isLoggedInVar,
  userProfileVar,
  userVar,
  fromAppVar,
  appSessionTokenVar,
} from 'graphql/cache';
import { updateIntercomUser } from 'helpers/users';

import './Login.scss';

const Login = () => {
  const history = useHistory();
  const redirectUrl = sessionStorage.getItem('redirect-url') || utils.getURLParam('redirect_url');
  const { trackEvent, update } = useIntercom();

  const [googleLoading, setGoogleLoading] = useState(false);
  const [loadingWixLogin, setLoadingWixLogin] = useState(false);

  const handleLoggedInUser = (loggedInUserInfo) => {
    const {
      token,
      user: {
        _id,
        profile: {
          companyname,
          name,
          address,
          image,
          vatnumber,
          contactnumber,
          terms,
          whatsappNumber,
          where,
        },
        emails,
      },
    } = loggedInUserInfo;

    if (loggedInUserInfo.user) {
      userProfileVar({
        name,
        companyname,
        email: emails[0].address,
        address,
        image,
        contactnumber,
        vatnumber,
        terms,
        whatsappNumber,
        where,
      });
      userVar({ ...loggedInUserInfo.user });

      utils.updateClarity({ _id, name, email: emails?.[0].address, loggedInAt: new Date() });

      if (token) {
        window.ga('send', 'event', 'user', 'sign-in');
        trackEvent('sign-in');
        updateIntercomUser(loggedInUserInfo.user, update);

        window.localStorage.setItem('prospero-token', token);
        window.localStorage.setItem('prospero-user-id', _id);
        isLoggedInVar(true);
        history.push(redirectUrl || Path.DASHBOARD);
        sessionStorage.setItem('redirect-url', '');
      }

      if (fromAppVar() === 'monday') {
        if (
          utils.verifyMondayAccount(
            loggedInUserInfo?.user?.monday?.account?.account?.id,
            appSessionTokenVar()
          )
        ) {
          history.push('/invalid');
        }
      }
    }
  };

  const handleLoginError = (err) => {
    let loginattempts = Number(utils.readCookie('loginattempts') || 0);
    loginattempts += 1;

    if (
      err.message === 'Google Authentication Error' ||
      err.message === 'Incorrect Email/Password'
    ) {
      const d1 = new Date();
      const expires = new Date(d1);
      expires.setMinutes(d1.getMinutes() + 30);
      utils.createCookie('loginattempts', loginattempts, expires);
      utils.createCookie('loginattemptsExpiresIn', expires, expires);
    } else if (err.message === 'Account Deleted') {
      return setLoginError('Account Deleted! Please contact support.');
    }
  };

  const [onGoogleLogin] = useLazyQuery(USER_GOOGLE_LOGIN, {
    onCompleted: (res) => {
      if (res.loginGoogleUser) {
        handleLoggedInUser(res.loginGoogleUser);
      }
      utils.createCookie('loginattempts', 0);
      setGoogleLoading(false);
    },
    onError: (err) => {
      handleLoginError(err);
      setGoogleLoading(false);
    },
    fetchPolicy: 'network-only',
  });

  const [onLogin, { loading }] = useLazyQuery(USER_LOGIN, {
    onCompleted: (res) => {
      if (res.loginUser) {
        handleLoggedInUser(res.loginUser);
      }
      utils.createCookie('loginattempts', 0);
    },
    onError: (err) => {
      handleLoginError(err);
      setLoginError('Wrong email or password');
    },
    fetchPolicy: 'network-only',
  });

  const [onWixLogin] = useLazyQuery(USER_WIX_LOGIN, {
    onCompleted: (res) => {
      if (res.loginWixUser) {
        handleLoggedInUser(res.loginWixUser);
      }
      utils.deleteCookie('instanceId');
      utils.createCookie('loginattempts', 0);
    },
    onError: (err) => {
      handleLoginError(err);
      utils.deleteCookie('instanceId');
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    document.title = 'Prospero - Login';
  });

  useEffect(() => {
    if (googleLoading) {
      return <Loader />;
    }
  });

  useEffect(() => {
    const instanceId = utils.readCookie('instanceId');
    if (instanceId && !loadingWixLogin) {
      setLoadingWixLogin(true);
      onWixLogin({
        variables: {
          instanceId,
        },
      });
      utils.deleteCookie('instanceId');
    }
  }, [loadingWixLogin, onWixLogin]);

  const [loginError, setLoginError] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const doLogin = (values) => {
    const { password } = values;
    let { email } = values;
    setLoginError('');

    if (email && password) {
      let loginattempts = Number(utils.readCookie('loginattempts') || 0);
      const loginattemptsExpiresIn = utils.readCookie('loginattemptsExpiresIn');
      const today = new Date();
      const expiryDate = new Date(loginattemptsExpiresIn);
      const diffMs = expiryDate - today;
      const diffMins = diffMs ? Math.round(((diffMs % 86400000) % 3600000) / 60000) : -1;
      if (loginattempts >= 5) {
        if (diffMins > 0) {
          return setTimeout(() => {
            setLoginFailed(true);
          }, 1000);
        }
      }
      email = email.toLowerCase();
      onLogin({ variables: { email, password } });
    }
  };

  const handleGoogleAuth = async (googleData) => {
    setGoogleLoading(true);
    const token = googleData.access_token;

    const profile = {};
    const affiliate = utils.getQueryStringValue('affiliate') || utils.readCookie('affiliate');
    const role = utils.getQueryStringValue('role');

    if (affiliate) profile.affiliate = affiliate;

    if (role === 'affiliate') profile.role = 'affiliate';

    const referrerUrl = utils.readCookie('referrer_url');
    if (referrerUrl) profile.referrerUrl = referrerUrl;

    const landingUrl = utils.readCookie('landing_url');
    if (landingUrl) profile.landingUrl = landingUrl;

    onGoogleLogin({ variables: { token, affiliate, landingUrl } });
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      handleGoogleAuth(codeResponse);
    },
    onError: (errorResponse) => {
      handleGoogleAuth(errorResponse);
    },
  });

  if (loginFailed) {
    const loginattemptsExpiresIn = utils.readCookie('loginattemptsExpiresIn');
    const today = new Date();
    const expiryDate = new Date(loginattemptsExpiresIn);
    const diffMs = expiryDate - today;
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

    return <LoginFailed diffMins={diffMins} setLoginFailed={setLoginFailed} />;
  }

  return (
    <Row className="login-container">
      <Col className="login-left">
        <Row className="header-login">
          <Col className="logo">
            <NavLink to={Path.HOME}>
              <img src={images.LOGO} alt="prospero-logo" height="20" />
            </NavLink>
          </Col>
        </Row>

        <div className="content-wrapper">
          <div className="heading-wrap">
            <h1 className="heading">Log In To Prospero</h1>
            <p className="sub-heading-text">Welcome back! We're excited to see you again</p>
            <div className="color-divider" />
          </div>
          <div className="error-message">{loginError}</div>
          <Form layout="vertical email-input" onFinish={doLogin}>
            <Form.Item
              label="Email"
              name="email"
              labelCol={false}
              rules={[
                {
                  required: true,
                  message: 'Please input your email',
                },
                {
                  type: 'email',
                  message: 'Please input a valid email',
                },
              ]}>
              <Input placeholder="email@example.com" tabIndex={1} />
            </Form.Item>
            <Form.Item
              className="password-label"
              label={
                <div className="custom-label">
                  <span>Password</span>
                  <NavLink to={Path.FORGOT_PASSWORD} className="forgot-password">
                    Forgot Password?
                  </NavLink>
                </div>
              }
              name="password"
              labelCol={false}
              rules={[
                {
                  required: true,
                  message: 'Please input your password',
                },
              ]}>
              <Input.Password
                placeholder="********"
                tabIndex={2}
                iconRender={(visible) => (visible ? <PasswordEyeShutIcon /> : <PasswordEyeIcon />)}
              />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block
              className="login-btn"
              tabIndex={4}
              disabled={loading || loadingWixLogin ? true : false}
              loading={loading || loadingWixLogin}>
              {(!loading || !loadingWixLogin) && <span className="spacer" />}
              <span className="btn-text">
                {loading || loadingWixLogin ? 'SIGNING IN' : 'SIGN IN'}
              </span>
            </Button>
          </Form>
          <div className="login-divider">
            <div className="inner-login-divider"> Or sign in with </div>
          </div>
          <CustomGoogleButton
            className="google-login-button"
            text="Google"
            onClick={() => login()}
          />

          <div className="login-link">
            New to Prospero?{' '}
            <NavLink
              to={`${Path.SIGNUP}${redirectUrl !== null ? `?redirect_url=${redirectUrl}` : ''}`}>
              Sign up for free
            </NavLink>
          </div>
        </div>
      </Col>
      <Col className="login-right">
        <WhatsNew />
      </Col>
    </Row>
  );
};

export default Login;
