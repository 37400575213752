import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { userVar } from 'graphql/cache';
import { DOWNLOAD_PROPOSAL, GET_SINGLE_PROPOSAL } from 'graphql/queries/proposalQueries';
import { UPDATE_PROPOSAL_SUBSCRIPTION } from 'graphql/queries/proposalSubscription';
import { loadProposalFont, refineDraftObject } from 'helpers/proposal';
import { fontListEnglish, fontListHebrew } from 'pages/Proposal/constants/constants';
import React, { createContext, useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import commonUtils from 'utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { useIntercom } from 'react-use-intercom';
import { ELEMENTS_VARIABLES, PROPOSAL_SUBSCRIPTION_TOPIC } from 'constants/index';
import { notification } from 'antd';
import * as _ from 'lodash';
import moment from 'moment';
import { UPDATE_PROPOSAL_MUTATION } from 'graphql/mutations/proposalMutation';
import {
  UPDATE_LIBRARY_SECTION_MUTATION,
  UPDATE_LIBRARY_TEMPLATE_MUTATION,
} from 'graphql/mutations/contentLibraryMutations';

const ProposalContext = createContext(null);

export const useProposalContext = () => useContext(ProposalContext);

let __fontsLoaded = false;

export const ProposalContextProvider = ({ value = {}, children }) => {
  const { path } = value;
  const [prop, setProp] = useState('');
  const user = useReactiveVar(userVar);
  const params = useParams();
  const [template, setTemplate] = useState('');
  const [section, setSection] = useState('');
  const [isEditingModal, setIsEditingModal] = useState(false);
  const [proposalSettings, setProposalSettings] = useState({});
  const [undoableProposalStack, setUndoableProposalStack] = useState({
    past: [],
    present: {},
    future: [],
  });
  const { trackEvent } = useIntercom();

  const [preventReload, setPreventReload] = useState(true);
  const undoableProposalStackRef = useRef(undoableProposalStack);
  const propRef = useRef(prop || template || section);
  const wixEditor = !!path?.includes('/proposal-editor');
  const [scrollingTo, setScrollingTo] = useState('');
  const [savingDraftProposal, setSavingDraftProposal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [shouldUndoRedoUpdate, setShouldUndoRedoUpdate] = useState(false);

  const tabID = sessionStorage.tabID ? sessionStorage.tabID : (sessionStorage.tabID = uuidv4());

  const type = path === '/PDF/:cid' ? commonUtils.getQueryStringValue('type') : '';
  const templateWixEditor = !!path?.includes('/template-editor');

  const [updateProposal] = useMutation(UPDATE_PROPOSAL_MUTATION, {
    onCompleted: ({ updateProposal }) => {
      // if (
      //   !propRef.current.draft ||
      //   !propRef.current.draft.signature ||
      //   propRef.current.draft.sectionorder
      // ) {
      //   let { draft } = propRef.current;
      //   draft = {
      //     ...draft,
      //     ...updateProposal.draft,
      //   };
      // updateProposal.draft = draft;
      // }
      if (
        (updateProposal.state === 'approved' && propRef.current.state !== updateProposal.state) ||
        !_.isEqual(propRef.current.draft.variables, updateProposal.draft.variables)
      ) {
        propRef.current = { ...propRef.current, ...updateProposal };
        setProp({ ...propRef.current, ...updateProposal });
      }
    },
    // onError: () => {},
  });

  const loadFonts = (proposalSectionTemplate) => {
    let prelist = [];
    const { language, draft } = proposalSectionTemplate;

    const titleFontFamily = draft?.titleFont?.family;
    const subtitleFontFamily = draft?.subTitleFont?.family;
    const bodyFontFamily = draft?.bodyFont?.family;

    if (language?.toLowerCase() === 'hebrew' || language?.toLowerCase() === 'arabic') {
      const defaultFont = user?.profile?.defaultProposalFont?.trim() || 'Assistant';
      const combinedFontList = (user?.uploadedFonts || propRef.current?.fonts || []).concat(
        fontListHebrew
      );
      prelist = combinedFontList.filter((fontList) =>
        [titleFontFamily, subtitleFontFamily, bodyFontFamily].includes(fontList.family)
      );
      if (!prelist.length) {
        prelist = [
          {
            family: defaultFont,
            source: 'Google',
            weights: [200, 300, 400, 600, 700, 800],
            italic: [],
            fallback: [],
          },
        ];
      }
    } else {
      const defaultFont = user?.profile?.defaultProposalFont?.trim() || 'Poppins';
      const combinedFontList = (user?.uploadedFonts || propRef.current?.fonts || []).concat(
        fontListEnglish
      );
      prelist = combinedFontList.filter((fontList) =>
        [titleFontFamily, subtitleFontFamily, bodyFontFamily].includes(fontList.family)
      );
      if (!prelist.length) {
        prelist = [
          {
            family: defaultFont,
            source: 'Google',
            weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
            type: 'sans-serif',
          },
        ];
      }
    }

    if (!__fontsLoaded) {
      __fontsLoaded = true;

      loadProposalFont(prelist, language, user, propRef.current);
    }
  };

  const handleUpdateEditing = (proposal) => {
    propRef.current = proposal;
    setProp(proposal);
    if (
      window.location.href.indexOf('/cd/') > 0 ||
      window.location.href.indexOf('proposal-preview') > 0 ||
      window.location.href.indexOf('/d/') > 0
    ) {
      if (proposal.isEditing) {
        if (proposal.isEditing?.tabID === tabID && proposal.isEditing?.uid === user._id) {
          setIsEditingModal(false);
        } else {
          setIsEditingModal(true);
        }
      } else {
        setIsEditingModal(false);
      }
    }
  };

  const { loading: isFetchingProposal, subscribeToMore } = useQuery(GET_SINGLE_PROPOSAL, {
    notifyOnNetworkStatusChange: true,
    variables: {
      pid: params.pid,
      _id: params.id,
      cid: params.cid,
    },
    skip:
      prop ||
      path === '/t/:tid' ||
      path === '/pt/:tid' ||
      path === '/s/:sid' ||
      path === '/ps/:sid' ||
      window.proposalId ||
      window.templateId ||
      window.wixTemplateId ||
      (path === '/PDF/:cid' && type !== 'proposal' && type !== 'wix-proposal' && !!type),
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchProposal }) => {
      if (fetchProposal) {
        const updatedProposal = JSON.parse(JSON.stringify(fetchProposal));

        if (updatedProposal.dateFormat) {
          setProposalSettings({ dateFormat: updatedProposal.dateFormat });
        }

        handleUpdateEditing({
          ...updatedProposal,
          pricing: updatedProposal.pricing || { strategy: 'table' },
        });

        if (
          path === '/w/:pid' ||
          path === '/d/:pid' ||
          path === '/t/:tid' ||
          path === '/s/:sid' ||
          wixEditor
        ) {
          const undoStack = {
            past: [fetchProposal.draft],
            present: fetchProposal.draft,
            future: [],
          };
          undoableProposalStackRef.current = undoStack;
          setUndoableProposalStack(undoStack);
        }

        if (!__fontsLoaded) loadFonts(updatedProposal);
      }
    },
  });

  const subscribeToUpdateProposal = () => {
    return subscribeToMore({
      document: UPDATE_PROPOSAL_SUBSCRIPTION,
      variables: { topic: `PROPOSALS_${prop.channel || prop.auid || prop._id}` },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        const { typename, proposal } = subscriptionData.data.updateProposalSubscription;

        if (
          user &&
          user._id !== proposal.uid &&
          user.teamId !== proposal.auid &&
          user._id !== proposal.auid &&
          user.teamId !== proposal.uid
        )
          return;

        if (params.cid && params.cid !== proposal._id) {
          return;
        }

        if (params.id && params.id !== proposal._id) {
          return;
        }

        if (params.sid && params.sid !== proposal._id) {
          return;
        }

        if (params.tid && params.tid !== proposal._id) {
          return;
        }

        if (params.pid && params.pid !== proposal.pid) {
          return;
        }

        if (window.proposalId && window.proposalId !== proposal.wixProposalId) {
          return;
        }

        let { draft } = propRef.current;
        draft = {
          ...draft,
          ...proposal.draft,
        };
        proposal.draft = draft;

        if (typename === 'UPDATE_PROPOSAL') {
          // if same tab ignore the data
          if (
            !!propRef?.current?.isEditing?.tabID &&
            propRef?.current?.isEditing?.tabID === proposal?.isEditing?.tabID &&
            propRef?.current?.isEditing?.tabID === tabID
          ) {
            return;
          }

          handleUpdateEditing(proposal);

          return { fetchProposal: proposal };
        } else if (typename === 'UPDATE_CLIENT_PROPOSAL') {
          handleUpdateEditing(proposal);

          return { fetchProposal: proposal };
        } else if (typename === 'UPDATE_TEMPLATE') {
          propRef.current = proposal;
          setTemplate(JSON.parse(JSON.stringify(proposal)));
        }
      },
    });
  };

  const getOccuranceCount = (text, value) => {
    if (!text?.length) {
      return 0;
    }

    return (text.match(new RegExp(value, 'g')) || []).length;
  };

  const getVariableOccuranceCount = (occurrences, block, variables, variableKeys) => {
    // level 1 - sender
    variableKeys.forEach((value) => {
      // level 2 - sender.firstName
      Object.keys(variables[value]).forEach((variable) => {
        occurrences[value][variable].count += getOccuranceCount(
          block.text,
          `{{${value}.${variable}}}`
        );
      });
    });

    return occurrences;
  };

  const updateLoaderState = (shouldShowLoader) => {
    setShowLoader(shouldShowLoader);
  };

  const handleProposalUndoRedo = (type, draftInfo, from, tempShouldUndoRedoUpdate) => {
    const { past, present, future } = undoableProposalStackRef.current;

    const { _id, pid, uid, auid, editEvent } = propRef.current;

    if (!past?.length && type === 'UNDO') {
      return;
    }

    switch (type) {
      case 'UNDO':
        const previous = past[past.length - 1];
        const newPast = past.slice(0, past.length - 1);

        handleUpdateTemplateProposalSection({
          id: _id,
          _id,
          pid,
          uid: uid,
          auid: auid,
          draft: previous,
          edited: new Date(),
          editEvent,
          channel: prop.channel,
          from: 'handleProposalUndoRedo',
        });
        undoableProposalStackRef.current = {
          past: newPast,
          present: previous,
          future: [present, ...future],
        };
        setUndoableProposalStack({
          past: newPast,
          present: previous,
          future: [present, ...future],
        });
        setShouldUndoRedoUpdate(true);
        break;
      case 'REDO':
        const [next] = future;
        const newFuture = future.slice(1);

        handleUpdateTemplateProposalSection({
          id: _id,
          _id,
          pid,
          uid: uid,
          auid: auid,
          draft: next,
          edited: new Date(),
          editEvent,
          channel: prop.channel,
          from: 'handleProposalUndoRedo',
        });
        undoableProposalStackRef.current = {
          past: [...past, present],
          present: next,
          future: newFuture,
        };
        setUndoableProposalStack({
          past: [...past, present],
          present: next,
          future: newFuture,
        });
        setShouldUndoRedoUpdate(true);
        break;
      default:
        // Delegate handling the action to the passed reducer
        if (!from) {
          const newPresent = { ...draftInfo };

          if (_.isEqual(present, newPresent)) {
            return setUndoableProposalStack(undoableProposalStackRef.current);
          }
          undoableProposalStackRef.current = {
            past: [...past, ...[present]],
            present: newPresent,
            future: [],
          };
          setUndoableProposalStack({
            past: [...past, ...[present]],
            present: newPresent,
            future: [],
          });
        }
        if (tempShouldUndoRedoUpdate) {
          setShouldUndoRedoUpdate(false);
        }
    }
  };

  const updateProposalPreHandler = (context, proposal) => {
    // update the proposal before making api call
    propRef.current = proposal;
    setProp(proposal);

    return new Promise((resolve, reject) => {
      updateProposal(context)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  };

  const [updateTemplate] = useMutation(UPDATE_LIBRARY_TEMPLATE_MUTATION, {
    onCompleted: ({ updateTemplate }) => {
      let { draft } = propRef.current;
      draft = {
        ...draft,
        ...updateTemplate.draft,
      };
      updateTemplate.draft = draft;
      propRef.current = updateTemplate;
      setProp(updateTemplate);
    },
    onError: () => {},
  });

  const [updateSection] = useMutation(UPDATE_LIBRARY_SECTION_MUTATION, {
    onCompleted: ({ updateLibrary }) => {
      const updatedSection = {
        ...JSON.parse(
          JSON.stringify({
            draft: {
              [updateLibrary._id]: {
                ...propRef.current.draft[updateLibrary._id],
                ...updateLibrary,
              },
              sectionorder: [updateLibrary._id],
            },
          })
        ),
        language: updateLibrary.language,
      };
      propRef.current = updatedSection;
      setProp(updatedSection);
    },
    onError: () => {},
  });

  const handleUpdateTemplateProposalSection = (
    templateProposalSection,
    handleSuccess = () => {},
    handleError = () => {}
  ) => {
    const { from } = templateProposalSection;
    delete templateProposalSection.from;
    if (path === '/d/:pid' || path === '/w/:pid' || wixEditor) {
      const oldProp = JSON.parse(JSON.stringify(propRef.current));
      let tempProp = {
        ...propRef.current,
        ...templateProposalSection,
        draft: { ...propRef.current.draft, ...(templateProposalSection.draft || {}) },
      };

      let tempShouldUndoRedoUpdate = shouldUndoRedoUpdate;
      if (from) {
        tempShouldUndoRedoUpdate = true;
      }

      if (
        (path === '/d/:pid' || path === '/w/:pid' || wixEditor) &&
        templateProposalSection.draft?.sectionorder &&
        (!prop.draft || !prop.draft.signature || !prop.draft.sectionorder)
      ) {
        undoableProposalStackRef.current = {
          past: [],
          present: tempProp.draft,
          future: [],
        };
        setUndoableProposalStack({
          past: [],
          present: tempProp.draft,
          future: [],
        });
      }

      if (templateProposalSection.draft)
        handleProposalUndoRedo(null, tempProp.draft, from, tempShouldUndoRedoUpdate);

      updateProposalPreHandler(
        {
          variables: {
            topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
              templateProposalSection?.channel ||
              templateProposalSection?.auid ||
              templateProposalSection?.uid
            }`,
            updateType: 'edit',
            proposal: { id: tempProp._id, ...tempProp },
          },
        },
        tempProp
      ).then(
        (response) => {
          response.data = response?.data || templateProposalSection;
          if (
            (!prop.draft || !prop.draft.signature || !prop.draft.sectionorder) &&
            response?.data?.updateProposal
          ) {
            const proposal = JSON.parse(
              JSON.stringify({ ...propRef.current, ...response.data.updateProposal })
            );
            propRef.current = { ...proposal };
            setProp({ ...proposal });
          }

          if (
            (response?.errors?.message === 'Failed to fetch' && path === '/d/:pid') ||
            (response?.errors?.message === 'Failed to fetch' && wixEditor)
          ) {
            propRef.current = { ...prop, draft: templateProposalSection.draft };
            setProp({ ...prop, draft: templateProposalSection.draft });
            window.localStorage.setItem(
              'unsavedproposal',
              JSON.stringify({ ...prop, draft: templateProposalSection.draft })
            );
          }

          updateLoaderState(false);
          handleSuccess(response);

          setSavingDraftProposal(false);
          if (preventReload) {
            window.onbeforeunload = () => {};
          }
        },
        (error) => {
          if (error.message === 'Proposal state is different.') {
            notification.destroy();
            notification.error({
              message: "Proposal can't be edited",
              description: 'Proposal is not in Draft state',
            });
          }
          updateLoaderState(false);
          setSavingDraftProposal(false);
          propRef.current = oldProp;
          setProp(oldProp);
          handleError(error);
        }
      );
    } else if (path === '/t/:tid' || templateWixEditor) {
      const oldProp = propRef.current;
      let tempProp = {
        ...propRef.current,
        ...templateProposalSection,
        draft: { ...propRef.current.draft, ...(templateProposalSection.draft || {}) },
      };
      propRef.current = tempProp;
      setTemplate(tempProp);

      let tempShouldUndoRedoUpdate = shouldUndoRedoUpdate;
      if (from) {
        tempShouldUndoRedoUpdate = true;
      }

      if (templateProposalSection.draft)
        handleProposalUndoRedo(null, tempProp.draft, from, tempShouldUndoRedoUpdate);

      updateTemplate({
        variables: {
          topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
            templateProposalSection?.channel ||
            templateProposalSection?.auid ||
            templateProposalSection?.uid
          }`,
          template: { id: tempProp._id, ...tempProp },
          templateUrl: '',
        },
      }).then(
        (response) => {
          response.data = response?.data || templateProposalSection;

          if (
            (!template.draft || !template.draft.signature || !template.draft.sectionorder) &&
            response?.data?.updateTemplate
          ) {
            const proposal = JSON.parse(
              JSON.stringify({ ...propRef.current, ...response.data.updateTemplate })
            );
            propRef.current = { ...proposal };
            setProp({ ...proposal });
          }

          if (
            (response?.errors?.message === 'Failed to fetch' && path === '/t/:tid') ||
            (response?.errors?.message === 'Failed to fetch' && wixEditor)
          ) {
            propRef.current = { ...template, draft: templateProposalSection.draft };
            setTemplate({ ...template, draft: templateProposalSection.draft });
            window.localStorage.setItem(
              'unsavedproposal',
              JSON.stringify({ ...template, draft: templateProposalSection.draft })
            );
          }

          updateLoaderState(false);
          handleSuccess(response);

          setSavingDraftProposal(false);
          if (preventReload) {
            window.onbeforeunload = () => {};
          }
        },
        (error) => {
          setSavingDraftProposal(false);
          propRef.current = oldProp;
          setTemplate(oldProp);
          handleError(error);
        }
      );
    } else if (path === '/s/:sid') {
      const oldProp = propRef.current;
      let tempProp = {
        ...propRef.current,
        ...templateProposalSection,
        draft: { ...propRef.current.draft, ...(templateProposalSection.draft || {}) },
      };
      propRef.current = tempProp;
      setSection(tempProp);

      let tempShouldUndoRedoUpdate = shouldUndoRedoUpdate;
      if (from) {
        tempShouldUndoRedoUpdate = true;
      }

      if (templateProposalSection.draft)
        handleProposalUndoRedo(null, tempProp.draft, from, tempShouldUndoRedoUpdate);

      updateSection({
        variables: {
          section: {
            ...section?.draft?.[params.sid],
            ...tempProp?.draft?.[params.sid],
            thumbnail: '',
          },
        },
      }).then(
        (response) => {
          response.data = response?.data || templateProposalSection;

          if (
            (!section.draft || !section.draft.signature || !section.draft.sectionorder) &&
            response?.data?.updateLibrary
          ) {
            const proposal = JSON.parse(
              JSON.stringify({ ...propRef.current, ...response.data.updateLibrary })
            );
            propRef.current = { ...proposal };
            setProp({ ...proposal });
          }

          if (response?.errors?.message === 'Failed to fetch' && path === '/s/:sid') {
            propRef.current = { ...template, draft: templateProposalSection.draft };
            setSection({ ...template, draft: templateProposalSection.draft });
            window.localStorage.setItem(
              'unsavedproposal',
              JSON.stringify({ ...templateProposalSection, sectionId: params.sid })
            );
          }

          updateLoaderState(false);
          handleSuccess(response);

          setSavingDraftProposal(false);
          if (preventReload) {
            window.onbeforeunload = () => {};
          }
        },
        (error) => {
          setSavingDraftProposal(false);
          propRef.current = oldProp;
          setTemplate(oldProp);
          handleError(error);
        }
      );
    }
  };

  const inlineStyleRangesReduction = (inlineStyleRanges) => {
    if (!inlineStyleRanges?.length) {
      return [];
    }
    const uniqueStyles = new Map();
    const result = [];

    for (let i = inlineStyleRanges.length - 1; i >= 0; i--) {
      const inlineStyle = inlineStyleRanges[i];
      // dont save highlights
      if (inlineStyle.style === 'HIGHLIGHT') {
        continue;
      }

      if (inlineStyle.style && inlineStyle.style.includes('rgba(')) {
        const key = `${inlineStyle.offset}-${inlineStyle.length}-${inlineStyle.style.substring(
          0,
          4
        )}`;
        if (!uniqueStyles.has(key)) {
          uniqueStyles.set(key, true);
          result.unshift(inlineStyle);
        }
      } else {
        result.unshift(inlineStyle);
      }
    }

    return result;
  };

  //eslint-disable-next-line
  const saveTemplateProposal = (what, handleSuccess = () => {}, handleError = () => {}) => {
    const { from } = what;
    delete what.from;

    console.log('What is saving', what);
    setScrollingTo('');
    setSavingDraftProposal(true);
    window.onbeforeunload = () => {
      return 'Changes you made may not be saved yet.';
    };

    const { _id, pid } = prop || template;
    const propData = propRef.current || prop || template || section;
    // let { draft } = findDraftObject({
    let newDraft = what.draft
      ? what.draft
      : refineDraftObject({
          what,
          proposal: propRef.current || prop || template || section,
        });

    propData.draft = {
      ...propData.draft,
      ...newDraft,
    };

    const rest = {};

    if (from === 'wizard' && what.state === 'edit') {
      const occurrences = JSON.parse(
        JSON.stringify(propData?.draft?.variables || ELEMENTS_VARIABLES)
      );

      if (propData?.draft?.variables) {
        /**
         * Sender variables
         */
        occurrences.sender = {
          ...occurrences.sender,
          email: {
            count: occurrences?.sender?.email?.count || 0,
            value: !!propData.email ? propData.email : '',
          },
          phone: {
            count: occurrences?.sender?.phone?.count || 0,
            value: !!propData.contactnumber ? propData.contactnumber : '',
          },
          company: {
            count: occurrences?.sender?.company?.count || 0,
            value: !!userVar()?.profile?.companyname ? userVar()?.profile?.companyname : '',
          },
          VAT: {
            count: occurrences?.sender?.VAT?.count || 0,
            value: !!userVar()?.profile?.vatnumber ? userVar()?.profile?.vatnumber : '',
          },
        };

        if (propData?.signature?.myname || propData?.draft?.signature?.myname || propData?.name) {
          const senderNames = (
            propData?.signature?.myname ||
            propData?.draft?.signature?.myname ||
            propData?.name
          ).split(' ');

          // eslint-disable-next-line prefer-destructuring
          occurrences.sender.firstName.value = senderNames[0];
          occurrences.sender.lastName.value = senderNames.slice(1, senderNames.length).join(' ');
          occurrences.sender.fullName.value = senderNames.join(' ');
        }

        newDraft = { ...newDraft, variables: occurrences };
      }
    } else {
      let occurrences = JSON.parse(
        JSON.stringify(propData?.draft?.variables || ELEMENTS_VARIABLES)
      );
      if (propData?.draft?.variables) {
        Object.keys(occurrences).forEach((value) => {
          Object.keys(occurrences[value]).forEach((variable) => {
            occurrences[value][variable].count = 0;
          });
        });

        const variableKeys = Object.keys(propData.draft.variables);

        // get variable occurrence count
        Object.keys(propData.draft).forEach((key) => {
          const keyExists = propData.draft?.sectionorder?.indexOf(key) > -1;

          if (key === 'header' || key === 'signature' || keyExists) {
            let blockKeys = [];

            if (key === 'header') {
              blockKeys = ['rawtitle', 'rawsubtitle', 'rawcontact', 'rawname', 'rawemail'];
            } else if (key === 'signature') {
              blockKeys = ['rawtitle', 'rawmyname', 'rawname'];
            } else {
              blockKeys = ['rawtitle', 'subrawtitle', 'raw', 'raw1', 'raw2', 'raw3'];
            }

            // loop for each block
            blockKeys.forEach((blockKey) => {
              // check if block exists
              if (propData.draft?.[key]?.[blockKey]?.blocks?.length) {
                propData.draft[key][blockKey].blocks.forEach((block) => {
                  // get and update the variable occurrence count
                  getVariableOccuranceCount(
                    occurrences,
                    block,
                    propData.draft.variables,
                    variableKeys
                  );
                });
              }
            });
          }
        });

        if (what?.expiryDate || what?.expiryDate === null) {
          /**
           * Proposal variables
           */
          occurrences.proposal = {
            ...occurrences.proposal,
            expireDate: {
              count: occurrences.proposal.expireDate.count || 0,
              value: what?.expiryDate ? moment(what.expiryDate).format('DD-MM-YYYY') : null,
            },
          };
        }

        newDraft = { ...newDraft, variables: occurrences };
      }
    }

    for (const [key, value] of Object.entries(what)) {
      if (!key.includes('draft')) {
        rest[key] = value;
      }
    }

    if (newDraft.signature) {
      const clientNames = newDraft?.signature?.rawname
        ? newDraft?.signature?.rawname.blocks[0].text.split(' ')
        : newDraft?.signature?.name.split(' ');

      rest.client = {
        ...propData.client,
        contact: clientNames.join(' '),
      };
    }

    const rawkeys = [
      'rawtitle',
      'subrawtitle',
      'rawsubtitle',
      'raw',
      'raw1',
      'raw2',
      'raw3',
      'rawcontact',
      'rawname',
      'rawemail',
      'rawmyname',
      'rawname',
    ];

    newDraft = JSON.parse(JSON.stringify(newDraft));

    // remove duplicate inlineStyleRanges
    Object.keys(newDraft).forEach((key) => {
      if (key !== 'variables') {
        rawkeys.forEach((rawkey) => {
          if (newDraft?.[key]?.[rawkey]?.blocks) {
            newDraft[key][rawkey].blocks.forEach((block, index) => {
              newDraft[key][rawkey].blocks[index].inlineStyleRanges = inlineStyleRangesReduction(
                block.inlineStyleRanges
              );
            });
          }
        });
      }
    });

    handleUpdateTemplateProposalSection(
      {
        from,
        id: _id,
        _id,
        pid,
        uid: prop.uid || template.uid || section.uid,
        auid: prop.auid || template.auid || section.uid,
        draft: newDraft,
        edited: new Date(),
        templateUrl: '',
        editEvent: prop.editEvent,
        channel: prop.channel,
        ...rest,
      },
      (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => handleError && handleError(error)
    );
  };

  const [generatePDF, { loading: downloadingPDF }] = useLazyQuery(DOWNLOAD_PROPOSAL, {
    skip: prop === null,
    fetchPolicy: 'cache-and-network',
    variables: {
      origin: window.location.origin,
      proposalId: prop ? prop._id : template ? template._id : '',
      ...(!user ? { cid: prop._id } : {}),
    },
  });

  const downloadFile = (url) => {
    // if (prop.wixProposalId) {
    //   const fileName = prop ? `${utils.getTitleText(prop, 'header')}_${prop._id}.pdf` : '';
    //   download(url, fileName, 'application/pdf');
    // } else {
    const link = document.createElement('a');
    link.href = url;
    link.download = '';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // }
  };

  const downloadPdf = ({ onDownloadCompleted, onErrorPopupClose }) => {
    const onCompleted = (data) => {
      // no need to prevent reload if user downloaded pdf
      setPreventReload(false);
      try {
        window.ga('send', 'event', 'pdf', 'click-download', window.location.href);
        trackEvent('click-download-pdf', {
          pid: prop?.pid,
          id: prop?._id,
          url: window.location.origin + '/pd/' + prop?._id,
        });
      } catch (error) {
        console.log(error);
      }
      downloadFile(data.downloadProposal);
      onDownloadCompleted?.();
    };
    const onError = (error) => {
      setPreventReload(false);
      commonUtils.showErrorMessage({
        title: 'Problem Downloading PDF',
        message: `Please try in a few minutes or contact support. Error ${error.message}`,
        onClose: () => onErrorPopupClose?.(),
      });
    };

    generatePDF({
      onCompleted,
      onError,
    });
  };

  const contextInitialValues = {
    proposal: JSON.parse(JSON.stringify(propRef.current || template || section)),
    prop,
    tabID,
    user,
    params,
    propRef,
    template,
    section,
    proposalSettings,
    isFetchingProposal,
    isEditingModal,
    wixEditor,
    undoableProposalStack,
    undoableProposalStackRef,
    preventReload,
    downloadingPDF,
    scrollingTo,
    savingDraftProposal,
    showLoader,
    shouldUndoRedoUpdate,
    templateWixEditor,
    updateLoaderState,
    handleProposalUndoRedo,
    setSavingDraftProposal,
    updateProposalPreHandler,
    handleUpdateTemplateProposalSection,
    saveTemplateProposal,
    setScrollingTo,
    setPreventReload,
    trackEvent,
    setUndoableProposalStack,
    subscribeToUpdateProposal,
    setProposalSettings,
    setProp,
    setSection,
    setTemplate,
    loadFonts,
    setIsEditingModal,
    downloadPdf,
    handleUpdateEditing,
    ...value,
  };

  return (
    <ProposalContext.Provider value={contextInitialValues}>{children}</ProposalContext.Provider>
  );
};

export default ProposalContextProvider;
